<template>
	<div class="flex">
		<div class="one">
			<div class="title">
				<div class="title_word">软测量表单</div>
			</div>
			<div class="first">
				<div class="flex">
					<div class="column1">二次风机电流变化阈值</div>
					<div class="column2">0.0</div>
					<div class="column3">A</div>
				</div>
				<div class="flex">
					<div class="column1">一次风机电流变化阈值</div>
					<div class="column2">0.0</div>
					<div class="column3">A</div>
				</div>
				<div class="flex">
					<div class="column1">二次风机电流均值统计时间</div>
					<div class="column2">0.0</div>
					<div class="column3">A</div>
				</div>
				<div class="flex">
					<div class="column1">一次风机电流均值统计时间</div>
					<div class="column2">0.0</div>
					<div class="column3">A</div>
				</div>
			</div>
			<div class="buttom float1 flex">
				<div class="words">锅炉运行标志</div>
				<div class="button"></div>
			</div>
		</div>
		<div class="one">
			<div class="title">
				<div class="title_word">佳值统计条件设置</div>
			</div>
			<div class="second flex">
				<div class="left">
					<div class="flex">
						<div class="column1">最佳单耗统计使能阈值</div>
						<div class="column2">0.0</div>
						<div class="column3">t/kg</div>
					</div>
					<div class="flex">
						<div class="column1">单耗变化存储阈值</div>
						<div class="column2">0.0</div>
						<div class="column3">t/kg</div>
					</div>
				</div>
				<div class="right">
					<div class="column1">过程变量实时均值统计时间</div>
					<div class="flex">
						<div class="time"></div>
						<div class="data">
							<div class="flex">
								<div class="column2">0.0</div>
								<div class="column3">s</div>
							</div>
							<!-- <div class="column1">统计时间</div> -->
						</div>
					</div>
				</div>
			</div>
			<div class="buttom float2 flex">
				<div class="words">锅炉运行标志</div>
				<div class="button"></div>
			</div>
		</div>
		<div class="one">
			<div class="title">
				<div class="title_word">负荷压力煤量建模</div>
			</div>
			<div class="third">
				<div class="column1">过程变量实时均值</div>
				<div class="flex">
					<div class="time"></div>
					<div class="data">
						<div class="flex">
							<div class="column2">0.0</div>
							<div class="column3">s</div>
						</div>
						<div class="column1">统计时间</div>
					</div>
				</div>
			</div>
			<div class="buttom float3 flex">
				<div class="words">锅炉运行标志</div>
				<div class="button"></div>
			</div>
		</div>
	<inputVal ref="inputVal"></inputVal>
	<Historical v-if="isHshow" @sendStatus = 'isHshow=false' :historyname = 'historyname' :node='node' :Lkname='Lkname' :infoList = 'infoList'></Historical>
	 <Cfbcysz
      v-if="flag == 5 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Cfbcysz>
	 <Cfbserve
      v-if="flag == 7 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Cfbserve>

	</div>
</template>
		
<script>	
			import inputVal from "@/components/inputVal.vue"; //输入框组件
			import Historical from "@/components/Historical.vue"  //历史趋势
			import Cfbcysz from "@/views/CfbBoiler/CfbCommonSetting/index.vue"; //常用设置组件
	import Cfbserve from "@/views/CfbBoiler/CfbOnlineService/index.vue"; //客服组件
			export default {
			    name: "CfbParameter3",
			    props:{
		        infoList:{
		            type:Object,
		            default: () =>{
		              return {}// 默认值
		            } 
		        }
		    },
			 components:{inputVal, Historical,Cfbserve,Cfbcysz},
			    data: () => {
			  return {
				  flag:6,
			      Manualname: "",
			      Manualnode: "",
			      ManualAname: "",
			      Manualtitname: "",
			      isComShow:false,
			      isHshow:false,
			      isMshow:false,
			      isRshow:false,
			      historyname:'',
			      node:'',
			      Lkname:'',
			      isIndex:'',
			      Firstcontrolname:'',
			      Firstcontroltitname:'',
			      Firstcontrolnode:'',
			      isFshow:false,
			      Rsfname:'',
			      Rsfnode:'',
			      Rsftitname:'',
			      projectData:'',
			      grouptime:null,
			      spotArr:[],
			      authInfo:[],
			      UserInfo:[],
			  }
			        
			 },
			   watch: {
		    infoList: {
		      handler(n, o) {
		        this.infoList = n;
				console.log(this.infoList)
		      },
		      deep: true, // 深度监听父组件传过来对象变化
		    },
		  },
			  created(){
			    this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			    this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			    this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
				 this.$bus.$on("sendMsg", (msg) => {
						// 兄弟组件发送来的消息
						this.flag = msg;
						});
			  },
			  methods:{
			      // 打开先控多窗口
			  toDetail(key,name,nodename,Aname,titname){
			   if(!this.authInfo[1]){
			    let str = this.authInfo[0]
			    // 判断是否为调试还是查看权限
			    if (str.charAt(str.length-1) == "r") {
			     this.$message.error('暂无调试权限');
			    }
			   }else{
			    let pathname=""
			    switch(key){
			     case 1:
			     pathname = 'firstcontol'
			     break
			     case 2:
			     pathname = 'Cfbkfirstcontol'
			     break
			     case 3:
			     pathname = 'CfbRfirstcontol'
			     break
			    }
			    const newurl = this.$router.resolve({name: pathname, query: {Firstcontrolname: name,Firstcontrolnode:nodename,Firstcontroltitname:titname}})
			    window.open(newurl.href,'_blank')
			   }
			  },
			         // 双击事件
			    Cclick(num,mark,node,name){
			      // 组装数据
			      clearTimeout(this.grouptime);
			      let spojobj = {
			        num:num,
			        mark:mark,
			        node:node,
			        name:name
			      }
			      this.spotArr.push(spojobj)
			      // 数组去重
			      let deWeightThree = () => {
			          let map = new Map();
			          for (let item of this.spotArr) {
			              if (!map.has(item.name)) {
			                 this.$message.closeAll()
			                  map.set(item.name, item);
			                 this.$message.success('已添加');
			              }else{
			                this.$message.closeAll()
			                this.$message.error('已添加到变量池里，切勿重复添加');
			              }
			          }
			       
			          return [...map.values()];
			      }
			      this.spotArr = deWeightThree();
			      localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			    },
			       isshowfase(){
			       this.isComShow = false
			     },
			    closeCompon(){
			
			      this.$emit('sendStatus', 'Param041',false)
			    },
			    isClose(val,val1){
			      switch(val){
			        case 'Historical':
			          return this.isHshow = val1
					  case 'CfbCommonSetting':
							case (this.flag == 5):	
							return  this.flag = 6,this.$bus.$emit("footersendMsg", 6)
							case 'Cfbserve':
							case (this.flag == 7):	
							return  this.flag = 6,this.$bus.$emit("footersendMsg", 6)
			      }
			    },
			    toIpt(data,name,historyname,node){
			
			      this.$refs.inputVal.open(data,name,historyname,node)
			    },
			    toCompon(key,name,name2,name3,titname){
			   this.isIndex = key
			   this.historyname = name
			   this.node = name2
			   this.Lkname = name3
			      clearTimeout(this.grouptime);
			      this.grouptime=setTimeout(() =>{
			          switch(key){
			      case 0:
					  console.log(1)
			          return this.isMshow = true,this.Manualname = name,this.Manualnode = name2,this.ManualAname = name3,this.Manualtitname = titname
			        case 1 :
			          return this.isFshow = true,this.Firstcontrolname = name,this.Firstcontrolnode = name2,this.Firstcontroltitname = titname 
			        case 2:
			          return  this.isHshow = true
			        case 4:
			    return  this.isRshow = true,this.Rsfname = name,this.Rsfnode = name2,this.Rsftitname = titname
			   }
			      },300);    
			  },
			    } 
			}
</script>
		
<style lang="scss" scoped>
	.one{
		width: 31.41vw;
		height: 28.33vh;
		background-image: url("~@/assets/images/CfbBoiler/data01.png");
		background-size: 100% 100%;
		margin-top: 7.87vh;
		margin-left: 0.89vw;
		.title{
			width: 8.43vw;
			height: 1.76vh;
			font-size: 1.85vh;
			line-height: 3.5vh;
			color: #ffffff;
			margin-left: 1vw;
		}
		.first{
			font-size: 1.85vh;
			line-height: 3.7vh;
			margin-top: 2.5vh;
			.column1{
				width: 13vw;
				color: #6acafb;
				text-align: right;
				margin-left: 6vw;
			}
			.column2{
				width: 4vw;
				color: #01ffba;
				text-align: center;
				margin-left: 1vw;
				cursor: pointer;
			}
			.column3{
				color: #2fc3e3;
			}
		}
		.float1{
			margin-top: 4.07vh;
		}
		.second{
			font-size: 1.85vh;
			line-height: 3.7vh;
			margin-top: 2.96vh;
			.left{
				margin-top: 3.7vh;
				.column1{
					width: 10vw;
					color: #6acafb;
					text-align: right;
					margin-left: 1.97vw;
				}
				.column2{
					width: 3vw;
					color: #01ffba;
					text-align: center;
					margin-left: 1vw;
					cursor: pointer;
				}
				.column3{
					color: #2fc3e3;
				}
			}
			.right{
				margin-left: 2.27vw;
				.time{
					width: 2.81vw;
					height: 5vh;
					background-image: url("~@/assets/images/CfbBoiler/data02.png");
					background-size: 100% 100%;
					margin-top: 1.5vh;
				}
				.column1{
					width: 12vw;
					color: #6acafb;
					margin-left: -2vw;
				}
				.column2{
					width: 2vw;
					color: #01ffba;
					cursor: pointer;
				}
				.column3{
					color: #2fc3e3;
				}
				.data{
					margin-left: 1vw;
					margin-top: 2vh;
				}
			}
		}
		.float2{
			margin-top: 6.67vh;
		}
		.third{
			font-size: 1.85vh;
			line-height: 3.7vh;
			margin-left: 12.4vw;
			margin-top: 2.96vh;
			.time{
				width: 2.81vw;
				height: 5vh;
				background-image: url("~@/assets/images/CfbBoiler/data02.png");
				background-size: 100% 100%;
				margin-top: 1.5vh;
			}
			.column1{
				width: 8vw;
				color: #6acafb;
			}
			.column2{
				width: 2vw;
				color: #01ffba;
				cursor: pointer;
			}
			.column3{
				color: #2fc3e3;
			}
			.data{
				margin-left: 1vw;
			}
		}
		.float3{
			margin-top: 6.67vh;
		}
		.buttom{
			margin-left: 9.01vw;
			.words{
				font-size: 2.22vh;
				line-height: 1.8vh;
				color: #8aeaff;
			}
			.button{
				width: 3.13vw;
				height: 1.85vh;
				margin-left: 1.67vw;
				background-color: #0de0ac;
				border-radius: 1px;
			}
		}
	}
</style>
